import { Checkbox, FormControlLabel, Switch } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import AdminHeader from "../admin/AdminHeader";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { editstore, getstorelist, setstorestatus } from "../API";
import {
  showErrorToast,
  showSuccessToast,
} from "../components/Toast/configToast";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Menu, Popover, Transition } from "@headlessui/react";
import { SlArrowDown } from "react-icons/sl";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { BsCheckCircleFill } from "react-icons/bs";
import { IoIosCloseCircle } from "react-icons/io";
import { TiEdit } from "react-icons/ti";
import StoreEdit from "../admin/Modals/StoreEdit"
import { FaEdit } from "react-icons/fa";

function Stores(props) {
  const [storecheck, setstorecheck] = useState(true);
  const [storelist, setstorelist] = useState();
  const [refresh, setrefresh] = useState();
  const [hideWhenClose, setHideWhenClose] = useState();
  const [storeClosedMessagePopup, setStoreClosedMessagePopup] = useState(false);
  const [storeClosedMessage, setStoreClosedMessage] = useState(
    "Store will re-open in 5 minutes."
  );
  const [selectedItem, setSelectedItem] = useState();
  const [customStoreClosedMessage, setCustomStoreClosedMessage] = useState("");
  const [selectedStore, setSelectedStore] = useState();
  const [toggleCustomStoreClosedMessage, setToggleCustomStoreClosedMessage] =
    useState(false);

  const storeClosedMessages = [
    "Store will re-open in 5 minutes.",
    "Store will re-open in 15 minutes.",
    "Store will re-open in 30 minutes.",
    "Store will re-open in 60 minutes.",
  ];

  const [openStoreEditModal, setOpenStoreEditModal]=useState();

  const navigate = useNavigate();
  const handleEditStore=(item)=>{
      setOpenStoreEditModal(true);
      setSelectedStore(item)
  }

  useEffect(() => {
    if (
      !localStorage.getItem("token") ||
      localStorage.getItem("token") === null ||
      localStorage.getItem("token") === "null" ||
      localStorage.getItem("token") === undefined
    ) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    getstorelist()
      .then((res) => {
        // console.log('Storelist response', res)

        if (res.data.status === 200 || res.data.status === "200") {
          setstorelist(res.data.message);
        } else {
          setstorelist([]);
        }
      })
      .catch((err) => {
        console.log("Error in Store list", err);
      });
  }, [refresh, navigate]);

  const IOSSwitch = styled((props) => (
    <Switch
      checked={storecheck}
      onChange={() => {
        setstorecheck(!storecheck);
      }}
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      color: "#E4261C",
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#29B400",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#EAEAEA" : "#EAEAEA",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 480,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
    height: 300,
    minHeight: "auto",
    maxHeight: "calc(100% - 100px)",
    overflow: "auto",
  };

  const handleStoreStatusPopup = (e, item) => {
    if (!e.target.checked) {
      setSelectedItem(item);
      setStoreClosedMessagePopup(true);
    } else {
      handlestorestatus(e, item);
    }

    setSelectedStore(item);
  };

  const handleStoreClosedMessage = (message) => {
    setStoreClosedMessage(message);
  };

  const handleStoreClosedMessageSubmission = (e) => {
    let today = new Date();
    if (storeClosedMessage === "Custom" && customStoreClosedMessage === "") {
      showErrorToast("Please enter a message");
      return;
    }
    setStoreClosedMessagePopup(false);

    setstorestatus(
      selectedItem?.storeId,
      "0",
      today.toISOString(),
      storeClosedMessage === "Custom"
        ? customStoreClosedMessage
        : storeClosedMessage
    )
      .then((res) => {
        // console.log("Set Store response", res);
        showSuccessToast(res.data.message);
        setrefresh(!refresh);
        setStoreClosedMessage("Store will re-open in 5 minutes.");
        setCustomStoreClosedMessage("");
      })
      .catch((err) => {
        console.log("Set Store err", err);
      });
  };

  const handlestorestatus = (e, item) => {
    let storestatus;
    let today = new Date();

    if (e.target.checked) {
      storestatus = "1";
    } else {
      storestatus = "0";
    }

    setstorestatus(item.storeId, storestatus, today.toISOString())
      .then((res) => {
        // console.log("Set Store response", res);
        showSuccessToast(res.data.message);
        setrefresh(!refresh);
      })
      .catch((err) => {
        console.log("Set Store err", err);
      });
  };

  const handleSingleStore = (item) => {
    navigate(`/items/${item.storeId}`, { state: item });
  };

  const handlehidewhenclose = (event, data) => {
    let storeData = {};

    storeData.hideWhenClosed = event.target.checked;
    setHideWhenClose(event.target.checked);

    editstore(data.storeId, storeData)
      .then((res) => {
        showSuccessToast(res.data.message.message);
         console.log(res);
      })
      .catch((err) => {
        showErrorToast(err.message);
        // console.log(err);
      });
  };

  return (
    <>
      <div className="DesktopMain-wrapper">
        <AdminHeader stores={true} confirmOrder={props?.confirmOrder} />
        <div className="container padding-top">
          <div className="row">
            <div className="col-md-12">
              <div className="Zone_page_content_wrapper_main_div">
                <div className="Zone_page_filter_div_wrapper">
                  <h3> Stores</h3>
                </div>
              </div>
              <div className="Stores_Page_store_list_table">
                {storelist?.length > 0 ? (
                  <table
                    className="Store_page_table"
                    cellPadding="0"
                    cellSpacing="0"
                  >
                    <thead>
                      <tr>
                        <th colSpan="2"> Name</th>
                        <th> Zone </th>
                        <th> Owner</th>
                                           
                          <th>Actions</th>
                        <th> </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {storelist?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td
                              onClick={() => handleSingleStore(item)}
                              style={{ cursor: "pointer" }}
                            >
                              {" "}
                              <img src={item.image} alt="" />
                            </td>
                            <td
                              onClick={() => handleSingleStore(item)}
                              style={{ cursor: "pointer" }}
                            >
                              <h3>
                                {item.name && Object.values(item.name)[0]}
                              </h3>
                              <p>
                                {item.description &&
                                  Object.values(item.description)[0]}
                              </p>
                            </td>
                            <td>
                              {" "}
                              {item.location &&
                                Object.values(item.location)[0]}{" "}
                            </td>
                            <td style={{ color: "#3CB0CC" }}>
                              <span className="">
                                {" "}
                                {item.storeManager.name
                                  ? item.storeManager.name
                                  : "Not Assigned"}
                              </span>{" "}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <FormControlLabel
                                control={<IOSSwitch sx={{ m: 1 }} />}
                                label=""
                                checked={item.status === 1 ? true : false}
                                // onChange={(e) => handlestorestatus(e, item)}
                                onChange={(e) =>
                                  handleStoreStatusPopup(e, item)
                                }
                              />
                            </td>
                            
                            <td>
                            <span className="store_edit_content_icon" onClick={()=>handleEditStore(item)}>
                              {/* <TiEdit/> */}
                              <FaEdit/>
                              </span>
                            </td>
                           
                            
                            <td style={{display:'flex',alignItems:'center', marginTop:'8px'}}>
                              <Checkbox
                                label="Hide when Close"
                                checked={
                                  hideWhenClose
                                    ? hideWhenClose
                                    : item.hideWhenClosed
                                }
                                onChange={(event) => {
                                  handlehidewhenclose(event, item);
                                  setrefresh(!refresh);
                                }}
                                sx={{
                                  color: "#3CB0CC",
                                  "&.Mui-checked": {
                                    color: "#3CB0CC",
                                  },
                                  transform: 'scale(0.7)'
                                }}
                              />
                             
                              <span style={{ marginLeft: '8px', fontSize: '0.9em',marginTop:'4px'}}>
                                Hide when Close</span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  <p> No Stores available</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal open={storeClosedMessagePopup} backdrop="static" keyboard={false}>
        <Box sx={style}>
          <div className="flex flex-col justify-between h-full NewOrder_modal_wrapper">
            <div className="flex flex-col gap-2">
              <div className="flex items-center justify-between">
                <h3 className="font-bold">Store Closed Message</h3>
                <div
                  onClick={() => {
                    setStoreClosedMessagePopup(false);
                    if (storeClosedMessage === "Custom") {
                      setStoreClosedMessage("Store will re-open in 5 minutes.");
                      setCustomStoreClosedMessage("");
                    }
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {" "}
                  <CancelOutlinedIcon sx={{ marginBottom: "0px" }} />
                  <span> Close</span>
                </div>
              </div>
              <div className="flex items-center w-full gap-2 text-sm font-semibold">
                <span>Current :</span>
                <Popover
                  as="div"
                  className="relative flex-grow p-2 px-3 text-xs text-white rounded-md bg-ottonomyBlue"
                >
                  {({ open }) => (
                    <>
                      <div>
                        <Popover.Button className="flex justify-between w-full h-auto pb-1 border-b focus:outline-none border-b-white">
                          {selectedStore?.storeClosedMessage &&
                          Object.values(selectedStore?.storeClosedMessage)[0] &&
                          Object.values(
                            selectedStore?.storeClosedMessage
                          )[0] === storeClosedMessage
                            ? Object.values(
                                selectedStore?.storeClosedMessage
                              )[0]
                            : storeClosedMessage}
                          <SlArrowDown className="mt-1 ml-2 font-semibold text-white" />
                        </Popover.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Popover.Panel
                          className={`absolute right-0 z-10 w-full overflow-y-scroll h-24 mt-[0.70rem] origin-top-right bg-transparent border-2 !border-[#00B7D4] divide-y divide-gray-100 rounded-lg focus:outline-none`}
                        >
                          {({ close }) => (
                            <>
                              <div className="w-full">
                                {storeClosedMessages?.map((message, index) => {
                                  return (
                                    <div
                                      key={"distribution__no_" + index}
                                      value={message}
                                    >
                                      <button
                                        onClick={() => {
                                          handleStoreClosedMessage(message);
                                          close();
                                        }}
                                        type="button"
                                        className={`group flex w-full px-2 items-center justify-start rounded-md py-2 text-xs text-ottonomyBlue hover:text-white hover:bg-ottonomyBlue`}
                                      >
                                        <span className="text-left line-clamp-1">
                                          {message}
                                        </span>
                                      </button>
                                    </div>
                                  );
                                })}
                              </div>
                              <button
                                type="button"
                                className={`text-gray-900 sticky bottom-0 group flex w-full gap-1 items-center justify-center 
                                        rounded-md ${
                                          !toggleCustomStoreClosedMessage
                                            ? "py-2"
                                            : "py-1"
                                        } bg-white text-sm hover:!bg-ottonomyBlue hover:text-white`}
                                onClick={() => {}}
                              >
                                <>
                                  {toggleCustomStoreClosedMessage ? (
                                    <div className="flex items-center justify-between w-full gap-1">
                                      <input
                                        className="mx-1 px-2 py-1 !placeholder:text-[16px] w-full rounded border-b-2 
                                                  border-gray-300 h-full text-black !placeholder:text-gray-600 focus:border-ottonomyBlue 
                                                    focus:outline-none"
                                        type="text"
                                        placeholder="Enter Custom Message"
                                        value={customStoreClosedMessage}
                                        onChange={(event) => {
                                          setCustomStoreClosedMessage(
                                            event.target.value
                                          );
                                          // formik.setValues({productCategory: event.target.value});
                                        }}
                                        onKeyDown={(event) => {
                                          if (
                                            event.target.value !== "" &&
                                            event.key === "Enter" &&
                                            customStoreClosedMessage !== ""
                                          ) {
                                            setToggleCustomStoreClosedMessage(
                                              false
                                            );
                                          }
                                        }}
                                      />
                                      <BsCheckCircleFill
                                        onClick={() => {
                                          setCustomStoreClosedMessage(false);
                                          close();
                                        }}
                                        className="w-[26px] h-auto fill-ottonomyBlue group-hover:fill-white
                                                    rounded-full transition-all duration-500 ease-in-out"
                                      />
                                      <IoIosCloseCircle
                                        onClick={() =>
                                          setToggleCustomStoreClosedMessage(
                                            !toggleCustomStoreClosedMessage
                                          )
                                        }
                                        className="w-8 h-auto transition-all duration-500 ease-in-out rounded-full fill-red-600 group-hover:fill-white"
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      className="flex items-center justify-center w-full gap-1"
                                      onClick={() =>
                                        setToggleCustomStoreClosedMessage(true)
                                      }
                                    >
                                      <AddCircleIcon
                                        className="w-5 h-5"
                                        aria-hidden="true"
                                      />
                                      <span>Custom Message</span>
                                    </div>
                                  )}
                                </>
                              </button>
                            </>
                          )}
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
                {/* {selectedStore?.storeClosedMessage && Object.values(selectedStore?.storeClosedMessage)[0]} */}
              </div>
            </div>
            {/* <div>
              <div className="d_flex_left cancelOrder_Modal_radio_wrapper">
                <input
                  type="radio"
                  name="reopenstore"
                  value="Store will re-open in 5 minutes."
                  onChange={(e) => handleStoreClosedMessage(e)}
                  defaultChecked
                />
                <p> Store will re-open in 5 minutes.</p>
              </div>

              <div className="d_flex_left cancelOrder_Modal_radio_wrapper">
                <input
                  type="radio"
                  name="reopenstore"
                  value="Store will re-open in 15 minutes."
                  onChange={(e) => handleStoreClosedMessage(e)}
                />
                <p> Store will re-open in 15 minutes.</p>
              </div>
              <div className="d_flex_left cancelOrder_Modal_radio_wrapper">
                <input
                  type="radio"
                  name="reopenstore"
                  value="Store will re-open in 30 minutes."
                  onChange={(e) => handleStoreClosedMessage(e)}
                />
                <p> Store will re-open in 30 minutes.</p>
              </div>
              <div className="d_flex_left cancelOrder_Modal_radio_wrapper">
                <input
                  type="radio"
                  name="reopenstore"
                  value="Store will re-open in 60 minutes."
                  onChange={(e) => handleStoreClosedMessage(e)}
                />
                <p> Store will re-open in 60 minutes.</p>
              </div>
              <div className="d_flex_left cancelOrder_Modal_radio_wrapper">
                <input
                  type="radio"
                  name="reopenstore"
                  value="Custom"
                  onChange={(e) => handleStoreClosedMessage(e)}
                />
                <p> Custom </p>
              </div>
              {storeClosedMessage === "Custom" ? (
                <div>
                  <textarea
                    className="Cancel_order_modal_textarea"
                    name="customStoreClosedMessage"
                    value={customStoreClosedMessage}
                    onChange={(e) => {
                      setCustomStoreClosedMessage(e.target.value);
                    }}
                  ></textarea>
                </div>
              ) : null}
            </div> */}

            <div className="CancelOrder_Modal_heading_submit_btn">
              <button
                onClick={() => handleStoreClosedMessageSubmission()}
                type="submit"
              >
                {" "}
                Submit
              </button>
            </div>
          </div>
        </Box>
      </Modal>


    <StoreEdit 
    openStoreEditModal={openStoreEditModal}
    setOpenStoreEditModal={setOpenStoreEditModal}
    selectedStore={selectedStore}
    setstorelist={setstorelist}
    // storeData={storeData}

    />
    </>
  );
}

export default Stores;
